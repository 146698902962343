
import { Component, Prop, Vue } from "vue-property-decorator";

type Tile = {
  id: number;
  contentable: {
    url: string;
    authorized: boolean;
  };
};

@Component
export default class MSSNGRtoolComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  mssngr = {
    authorized: this.tile.contentable.authorized,
    url: this.tile.contentable.url
  };

  created() {
    if (!this.tile.contentable.authorized) this.token();
  }

  token() {
    this.$store.dispatch("mssngr/token", this.tile.id).then(response => {
      this.mssngr = response.data.data;
    });
  }
}
